

const NoPage = () => {
    return(
        <div>
            404 - No Page
        </div>
    )
};

export default NoPage;