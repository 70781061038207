import axios from "axios";
import { API_URL } from '../Constants';

class userDataService{
    // PUBLIC
    async login(data) {
        return axios.post(`${API_URL}login/`, data);
    };

    setPassword(passwordObject){
        return axios.post(`${API_URL}setPassword/`, passwordObject);
    };

    // PROTECTED
    getUser(userCredentials){
        return axios.get(`${API_URL}getUser/${userCredentials.id}`, {headers:{'Authorization':userCredentials.accessToken}} );
    };

    updatePassword(passwordObject){
        return axios.post(`${API_URL}updatePassword/`, passwordObject, {headers:{'Authorization':passwordObject.accessToken}} );
    };

    createUser(accessToken,userObject){
        return axios.post(`${API_URL}createUser/`, userObject, {headers:{'Authorization':accessToken}} );
    };

    updateUser(accessToken,userObject){
        return axios.post(`${API_URL}updateUser/`, userObject, {headers:{'Authorization':accessToken}} );
    };

    resetPassword(accessToken,userObject){
        return axios.post(`${API_URL}resetPassword/`, userObject, {headers:{'Authorization':accessToken}} );
    };
    
    getUserList(accessToken,filterObject){
        return axios.get(`${API_URL}getUserList/`, {headers:{'Authorization':accessToken}, params:filterObject});
    };


};

userDataService = new userDataService()
export default userDataService;