import './NavigationBar.css'
import { useContext, useEffect, useState } from 'react';
import {Context} from '../Context';

import CornerstoneLogo from '../refs/Cornerstone.svg';
import CornerstoneSymbol from'../refs/CornerstoneSymbol.svg';

import NavLinks from './NavLinks';

import { FaAnglesLeft, FaAnglesRight, FaBars, FaX  } from "react-icons/fa6";

const LogoHeight = 75;
const navMargin = 10;
const expanderHeight = 20;


const NavExpander = ({expanded, minimizeNav, maximizeNav}) => {
    const navExpanderCssProps = {
        '--expanderHeight' : `${expanderHeight}px`
    };

    return (
        <div className='Nav-Expander' style={navExpanderCssProps}>
            {   expanded &&
                <FaAnglesLeft className='Nav-Control-Symbol' onClick={() => minimizeNav()}/>
            }
            {   !expanded &&
                <FaAnglesRight className='Nav-Control-Symbol' onClick={() => maximizeNav()}/>
            }
        </div>
    )
};

const NavigationBar = ({mobile=false, expanded, minimizeNav, maximizeNav, optionsShown, toggleOptions, userMenuShown, toggleUserMenu}) => {
    const contextObject = useContext(Context);
    const [ navLinkHeight, setNavLinkHeight ] = useState(contextObject.screenHeight-LogoHeight-(navMargin*2));
    const [ initials, setInitials ] = useState('');

    useEffect(() => {
        if(contextObject.activeUser.name){
            let names = contextObject.activeUser.name.split(' ')
            let initials = names[0][0]+names[1][0];
            setInitials(initials);
        }
    },[contextObject.activeUser.name]);

    useEffect(() => {
        setNavLinkHeight(contextObject.screenHeight-LogoHeight-(navMargin*3)-expanderHeight)
    },[contextObject.screenHeight])

    const navCssProps = {
        '--navLinksHeight': `${navLinkHeight}px`,
        '--navLogoHeight' : `${LogoHeight}px`,
        '--navMargin' : `${navMargin}px`,
    };
    if(mobile){
        return(
            <>
            <div className='Mobile-Navbar'>
                <div className='Mobile-Nav-Options'>
                {   !optionsShown &&
                    <FaBars onClick={() => toggleOptions()} className='Mobile-Navbar-Bars'/>
                }
                {   optionsShown &&
                    <FaX onClick={() => toggleOptions()} className='Mobile-Navbar-Bars'/>
                }
                </div>

                <div className='Mobile-Nav-Logo'>
                    <img src={CornerstoneLogo} alt='Cornerstone Logo' className='Mobile-Nav-Logo-Image'/>
                </div>

                <div className='Mobile-Nav-User'>
                    <div className='Mobile-Nav-User-Bubble' >
                        <div className='Mobile-Nav-User-Inner-Bubble'>
                            {initials}
                        </div>
                    </div>
                </div>
            </div>
            </>

        )
    }
    else{
        return(
            <div className='Desktop-Navbar' style={navCssProps}>
                <div className='Nav-Logo' >
                    {   expanded &&
                        <img src={CornerstoneLogo} className='Nav-Logo' alt='Cornerstone Logo'/>
                    }
                    {   !expanded &&
                        <img src={CornerstoneSymbol} className='Nav-Logo'alt='Cornerstone Symbol'/>
                    }
                </div>

                <NavLinks navList={contextObject.navList} selectedNav={contextObject.selectedNav} setSelectedNav={contextObject.setSelectedNav} expanded={expanded}/>
                    
                <NavExpander expanded={expanded} minimizeNav={minimizeNav} maximizeNav={maximizeNav} />
            </div>
        )
    }
};

export default NavigationBar;