import './NavLinks.css';

const NavLinks = ({navList, selectedNav, setSelectedNav, expanded}) => {
    return(
        <div className='Nav-Links'>
        {   
            navList.map((navObject, key)=> {
                if(navObject.text === selectedNav){
                    return(
                        <div key={key} className='Nav-Link-Box-Selected' onClick={() => setSelectedNav(navObject.text)}>
                            <navObject.symbol className='Nav-Link-Symbol'/>
                            {   expanded &&
                            <div className='Nav-Link-Text'>
                                {navObject.text}
                            </div>
                            }
                        </div>
                    )
                }
                else{
                    return(
                        <div key={key} className='Nav-Link-Box' onClick={() => setSelectedNav(navObject.text)}>
                            <navObject.symbol className='Nav-Link-Symbol'/>
                            {   expanded &&
                            <div className='Nav-Link-Text'>
                                {navObject.text}
                            </div>
                            }
                        </div>
                    )
                }
            })
        }
        </div>
    )
};

export default NavLinks;