import { useEffect, useState, useContext } from 'react';
import './DisplayWindow.css';

import NavigationBar from '../NavigationBar/NavigationBar';
import PrimaryWindow from '../PrimaryWindow/PrimaryWindow';
import NavLinks from '../NavigationBar/NavLinks';

import {Context} from '../Context';

const widthToCollapse = 1370;   // EXPANDED PAST THIS
const widthToTop = 860;         // MOBILE WIDTH
const minimizedNavWidth = 65;   // NAVBAR WIDTH COLLAPSED
const expandedNavWidth = 220;   // NAVBAR WIDTH EXPANDED
const topBarHeight = 68;        // MOBILE NAVBAR HEIGHT
const rateOfChange = 1;
const distanceOfChange = 5;
const userHeaderHeight = 100;


const MobileMenu = ({navList, selectedNav, setSelectedNav, expanded=true}) => {
    return(
        <div className='Mobile-Nav-Menu-Inner'>
            <NavLinks navList={navList} selectedNav={selectedNav} setSelectedNav={setSelectedNav} expanded={expanded}/>
        </div>
    )
};

const DisplayWindow = () => {
    const contextObject = useContext(Context);

    const [ navDisplay, setNavDisplay ] = useState(3);  // 2 - Expanded     1 - Minimized       0 - At Top
    const [ navWidth, setNavWidth ] = useState(expandedNavWidth);
    const [ primaryWidth, setPrimaryWidth ] = useState(contextObject.screenWidth-expandedNavWidth);
    const [ primaryHeight, setPrimaryHeight ] = useState(contextObject.screenHeight-topBarHeight);
    const [ expanded, setExpanded ] = useState(true);
    const [ optionsShown, setOptionsShown ] = useState(false);
    const [ userMenuShown, setUserMenuShown ] = useState(false);
    const [ menuMargin, setMenuMargin ] = useState(contextObject.screenWidth);

    useEffect(() => {
        setPrimaryWidth(contextObject.screenWidth-navWidth);
    },[navWidth]);

    // CSS PROPS
    const navCssProps = {
        '--navWidth': `${navWidth}px`,
        '--navHeight': `${topBarHeight}px`,
    };

    const menuCssProps = {
        '--menuWidth' : `${primaryWidth+5}px`,
        '--menuMargin' : `${-menuMargin}px`,
        '--menuHeight' : `${primaryHeight}px`,
        '--topBarHeight' : `${topBarHeight}px`
    };

    const userCssProps = {
        '--menuWidth' : `${primaryWidth+5}px`,
        '--menuMargin' : `${menuMargin}px`,
        '--menuHeight' : `${primaryHeight}px`,
        '--topBarHeight' : `${topBarHeight}px`,
        '--userHeaderHeight' : `${userHeaderHeight}px`
    };

    const primaryCssProps = {
        '--primaryWidth' : `${primaryWidth}px`,
        '--primaryHeight' : `${primaryHeight}px`
    };

    const minimizeNav = (newNavWidth=expandedNavWidth) => {
        setTimeout(() =>{
            if(newNavWidth >= minimizedNavWidth){
                newNavWidth = newNavWidth-distanceOfChange
                setNavWidth(newNavWidth);
                setExpanded(false);
                minimizeNav(newNavWidth);
            }
        },rateOfChange)
    };

    const maximizeNav = (newNavWidth=minimizedNavWidth) => {
        setTimeout(() =>{
            if(newNavWidth <= expandedNavWidth){
                newNavWidth = newNavWidth+distanceOfChange
                setNavWidth(newNavWidth);
                maximizeNav(newNavWidth);
            }
            else{
                setExpanded(true);
            }
        },rateOfChange)
    };

    const shrinkOptions = (newMenuMargin = menuMargin) => {
        setTimeout(() =>{
            if(newMenuMargin < primaryWidth){
                newMenuMargin = newMenuMargin + distanceOfChange
                setMenuMargin(newMenuMargin);
                shrinkOptions(newMenuMargin);
            }
            else{
                setOptionsShown(false);
                setUserMenuShown(false);
            }
        },rateOfChange)
    };

    const enlargeOptions = (newMenuMargin = menuMargin) => {
        setTimeout(() =>{
            if(newMenuMargin > 0){
                newMenuMargin = newMenuMargin - distanceOfChange
                setMenuMargin(newMenuMargin);
                enlargeOptions(newMenuMargin);
            }
        },rateOfChange)
    };

    const toggleOptions = () => {
        if(userMenuShown){
            // SKIP
        }
        else{
            if(optionsShown){
                shrinkOptions();
            }
            else{
                setOptionsShown(true);
                enlargeOptions();
            }
        }
    };

    const toggleUserMenu = () => {
        if(optionsShown){
            // SKIP
        }
        else{
            if(userMenuShown){
                shrinkOptions();
            }
            else{
                setUserMenuShown(true);
                enlargeOptions();
            }
        }
    };

    const selectNavOption = (navSelection) => {
        toggleOptions();
        contextObject.setSelectedNav(navSelection);
    };

    const selectUserOption = (userSelection) => {
        toggleUserMenu();
        contextObject.setUserOption(userSelection);
    };

    // CHANGES DISPLAY BASED ON SCREEN HEIGHT - MOBILE ONLY
    useEffect(() => {
        if(contextObject.screenWidth < widthToTop) setPrimaryHeight(contextObject.screenHeight-topBarHeight-1);
        else setPrimaryHeight(contextObject.screenHeight);
    },[contextObject.screenHeight]);

    // CHANGES DISPLAY BASED ON SCREEN WIDTH
    useEffect(() => {
        // COLLAPSED NAV BAR
        if(contextObject.screenWidth <= widthToCollapse  && contextObject.screenWidth >= widthToTop) {
            setNavDisplay(1);
            setNavWidth(minimizedNavWidth);
            setPrimaryWidth(contextObject.screenWidth-minimizedNavWidth-1);
            setExpanded(false);
        }

        // EXPANDED NAV BAR
        else if(contextObject.screenWidth > widthToCollapse){
            setNavDisplay(2);
            setNavWidth(expandedNavWidth);
            setPrimaryWidth(contextObject.screenWidth-expandedNavWidth-1);
            setExpanded(true);
        }

        // MOBILE
        else {
            setNavDisplay(0);
            setNavWidth(0);
            setPrimaryWidth(contextObject.screenWidth);
            setPrimaryHeight(contextObject.screenHeight-topBarHeight);
            setExpanded(false);
        }
    },[contextObject.screenWidth]);

//  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -   -
//  RENDER BELOW

    // MOBILE EXPERIENCE
    if(navDisplay < 1){
        return(
            <div className='Mobile-Display-Window'>

                <div className='Mobile-Nav'>
                    <NavigationBar mobile={true} optionsShown={optionsShown} toggleOptions={toggleOptions} userMenuShown={userMenuShown} toggleUserMenu={toggleUserMenu}/>
                </div>

                <div className='Mobile-Primary' style={primaryCssProps}>
                    <PrimaryWindow />
                </div>

                {   optionsShown &&
                        <div className='Mobile-Nav-Menu' style={menuCssProps} >
                            <MobileMenu navList={contextObject.navList} selectedNav={contextObject.selectedNav} setSelectedNav={selectNavOption} expanded={true}/>
                        </div>
                }

            </div>

        )
    }

    // DESKTOP EXPERIENCE
    else{
        return(
            <div className='Desktop-Display-Window'>
                <div className='Desktop-Nav' style={navCssProps}>
                    <NavigationBar expanded={expanded} minimizeNav={minimizeNav} maximizeNav={maximizeNav}/>
                </div>
                <div className=''>

                </div>
                <div className='Desktop-Primary' style={primaryCssProps}>
                    <PrimaryWindow />
                </div>
            </div>
        )
    }
};

export default DisplayWindow;