import { useEffect, useState } from 'react';
import './ProjectBookingReview.css';


const ProjectBookingReview = () => {
    const [testInput, setTestInput] = useState('');
    const [testInput1, setTestInput1] = useState('');

    useEffect(() => {
        console.log(testInput)
    },[testInput])

    return(
        <div className='Project-Booking-Review'>

            <div className='Project-Booking-Review-Temp'>
                Project Booking Review Coming Soon 
            </div>

        </div>
    )
};

export default ProjectBookingReview;