import axios from "axios";
import { API_URL } from '../Constants';


class newVendorDataService{
    submitVoidedCheck(voidedCheck, filename) {
        const formData = new FormData();
        formData.append("file", voidedCheck);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postVoidedCheck/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitCoi(coi, filename) {
        const formData = new FormData();
        formData.append("file", coi);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postCoi/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitSignature(signature, filename) {
        const formData = new FormData();
        formData.append("file", signature);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postSignature/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitVendorRecord(vendorObject) {
        return axios.post(`${API_URL}postVendorRecord/`, vendorObject);
    };

    updateVendorRecord(vendorObject) {
        return axios.post(`${API_URL}updateVendorRecord/`, vendorObject);
    };

    getW9() {
        window.open(`${API_URL}getW9/`)
    };

    submitW9(w9, filename) {
        const formData = new FormData();
        formData.append("file", w9);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postW9/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    };

    submitIntWire(intWire, filename) {
        const formData = new FormData();
        formData.append("file", intWire);
        formData.append('fileName', filename);
        return axios.post(`${API_URL}postIntWire/`, formData, {headers:{'content-type': 'multipart/form-data'}});
    }
};


newVendorDataService = new newVendorDataService()
export default newVendorDataService;