import { useEffect, useState } from 'react';
import './FixedAssetsReview.css';


const FixedAssetsReview = () => {
    const [testInput, setTestInput] = useState('');
    const [testInput1, setTestInput1] = useState('');

    useEffect(() => {
        console.log(testInput)
    },[testInput])

    return(
        <div className='Fixed-Assets-Review'>

            <div className='Fixed-Assets-Review-Temp'>
                Fixed Assets Review Coming Soon 
            </div>

        </div>
    )
};

export default FixedAssetsReview;