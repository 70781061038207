import { useState } from 'react';
import './UsersWindow.css';
import { ImCheckboxUnchecked,ImCheckboxChecked } from "react-icons/im";
import { IoMdSearch } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { FaCheck } from "react-icons/fa";

import { RotatingLines } from "react-loader-spinner";

import { useContext } from 'react';
import {Context} from '../Context';
import { useEffect } from 'react';

import userDataService from '../Services/UserService';

const checkFieldWidth = 100;
const nameFieldWidth = 180;
const emailFieldWidth = 280;

const SearchBar = ({setSearch,search}) => {
    return(
        <div className='Users-Header-Search'>
            <div className='Users-Header-Search-Bar' >
                <IoMdSearch className='Users-Header-Search-Icon'/>
                <input className='Users-Header-Search-Input' value={search} onChange={(e => setSearch(e.target.value))} placeholder='Search'/>
            </div>
            
        </div>
    )
};

const UsersHeader = ({setSearch,search,userCount,setAddingUser}) => {
    const ManagedUsersSection = () => {
        return(
            <div className='Users-Header-Managed'>
                <div className='Users-Header-Top-Bar'>
                    <div className='Users-Header-Top-Bar-Text'>
                        Managed Users
                    </div>
                </div>

                <div className='Users-Header-Lower'>
                    <div className='Users-Header-Lower-Left'>
                        <div className='Users-Header-Lower-Left-Count'>
                            Managed Users ({userCount})
                        </div>
                        <div  className='Users-Header-Lower-Left-Text'>
                            Managed users are employees in your Cornerstone IO Enterprise. Privileges control access in the system for managed users.
                        </div>
                    </div>
                    <div className='Users-Header-Lower-Right'>
                        <button className='Users-Header-Button' onClick={() => setAddingUser(true)}>Add User</button>
                    </div>
                </div>
            </div>
        )
    };

    return(
        <div className='Users-Header'>
            <SearchBar setSearch={setSearch} search={search}/>
            <ManagedUsersSection />
            <ColumnHeaders />
        </div>
    )
};

const UserInputField = ({editting, value, setValue,width}) => {
    return(
    <>
        {   !editting &&
        <div className='Users-Display-Field' style={{'width': width}}>
            {value}
        </div>
        }

        {   editting &&
        <input className='Users-Display-Field User-Input' onChange={e => {setValue(e.target.value);}} value={value} style={{'width': width}}/>
        }
    </>
    )
};

const UserCheckField = ({editting, value, setValue, width}) => {
    const handleClick = () => {
        if(editting){
            setValue(!value);
        };
    };
    return(
    <div className='Users-Display-Field Field-Centered'  style={{'width': width}}>
        {   !value &&
            <ImCheckboxUnchecked className='Users-Display-Unchecked' onClick={() => handleClick()}/>
        }
        {   value &&
            <ImCheckboxChecked className='Users-Display-Checked' onClick={() => handleClick()}/>
        }
    </div>
    )
};

const ColumnHeaders = () => {
    return(
        <div className='Users-Header-Column-Headers'>
            <div style={{'width': nameFieldWidth}} >Name</div>
            <div style={{'width': emailFieldWidth}}>Email</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Vendors</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Project Booking</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Project Booking Review</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Fixed Assets</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Fixed Asset Review</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Admin</div>
            <div style={{'width': checkFieldWidth}} className='Field-Centered'>Status</div>
        </div>

    )
};

const UserRow = ({userObject,updateExistingUser,resetUsersPassword,getUserList}) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ vendorPriv, setVendorPriv ] = useState(false);
    const [ projectBookingPriv, setProjectBookingPriv ] = useState(false);
    const [ projectBookingReviewPriv, setProjectBookingReviewPriv ] = useState(false);
    const [ fixedAssetPriv, setFixedAssetPriv ] = useState(false);
    const [ fixedAssetReviewPriv, setFixedAssetReviewPriv ] = useState(false);
    const [ adminPriv, setAdminPriv ] = useState(false);
    const [ status, setStatus ] = useState(false);
    
    const [ toolsShown, setToolsShown ] = useState(false);
    const [ editting, setEditting ] = useState(false);

    const createUserObject = () => {
        const newUserObject = {
            userId: userObject.id,
            email: email,
            password: password,
            name: name,
            vendorPriv: vendorPriv,
            adminPriv: adminPriv,
            projectBookingPriv: projectBookingPriv,
            projectBookingReviewPriv: projectBookingReviewPriv,
            fixedAssetPriv: fixedAssetPriv,
            fixedAssetReviewPriv: fixedAssetReviewPriv,
            status: status,
        };

        return newUserObject;
    };

    useEffect(() => {
        console.log(userObject)
        setName(userObject.name);
        setEmail(userObject.email);
        setVendorPriv(userObject.vendor_priv);
        setProjectBookingPriv(userObject.project_booking_priv);
        setProjectBookingReviewPriv(userObject.project_booking_review_priv);
        setFixedAssetPriv(userObject.fixed_asset_priv);
        setFixedAssetReviewPriv(userObject.fixed_asset_review_priv);
        setAdminPriv(userObject.admin_priv);
        setStatus(userObject.active);
    },[userObject]);



    const ToolBar = ({editting,toolsShown,updateExistingUser}) => {
        const [ buttonText, setButtonText ] = useState('Edit');

        const handleClick = () => {
            if(editting){
                updateExistingUser(createUserObject());
                setEditting(false);
                getUserList();
            }
            else setEditting(true);
        };

        const handleCancel = () => {
            setEditting(false);
            getUserList();
        };

        const handlePasswordReset = () => {
            resetUsersPassword(createUserObject());
            setEditting(false);
            getUserList();
        };

        useEffect(()=> {
            if(editting) setButtonText('Submit')
            else setButtonText('Edit')
        },[editting]);

        if(toolsShown || editting){
            return(
                <div className='Users-Display-Tools'>
                    { editting &&
                    <div className='Users-Display-Tools-Button' onClick={() => handlePasswordReset()}>
                        Reset Password
                    </div>  
                    }
                    <div className='Users-Display-Tools-Button' onClick={() => handleClick()}>
                        {buttonText}
                    </div>
                    { editting &&
                    <div className='Users-Display-Tools-Button' onClick={() => handleCancel()}>
                        Cancel
                    </div>  
                    }
                </div>
            )
        }
        else return <></>

    };

    return(
        <div onMouseOver={() => setToolsShown(true)} onMouseOut={() => setToolsShown(false)}>
        <div className='Users-Display-Row' >
            <UserInputField editting={editting}  value={name} setValue={setName} width={nameFieldWidth}/>
            <UserInputField editting={editting}  value={email} setValue={setEmail} width={emailFieldWidth}/>
            <UserCheckField  editting={editting}  value={vendorPriv} setValue={setVendorPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={projectBookingPriv} setValue={setProjectBookingPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={projectBookingReviewPriv} setValue={setProjectBookingReviewPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={fixedAssetPriv} setValue={setFixedAssetPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={fixedAssetReviewPriv} setValue={setFixedAssetReviewPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={adminPriv} setValue={setAdminPriv} width={checkFieldWidth}/>
            <UserCheckField  editting={editting}  value={status} setValue={setStatus} width={checkFieldWidth}/>
        </div>
        <div className='Users-Display-Row-Border'>
            <ToolBar editting={editting} toolsShown={toolsShown} updateExistingUser={updateExistingUser}/>
        </div>
        </div>
    );
};

const UsersDisplay = ({userList,updateExistingUser,resetUsersPassword,getUserList}) => {
    return(
        <div className='Users-Display'>
            <div className='Users-Display-Window'>
                {   userList &&
                    userList.map((userObject,key) => (
                        <UserRow key={key} userObject={userObject} updateExistingUser={updateExistingUser} resetUsersPassword={resetUsersPassword} getUserList={getUserList}/>
                    ))
                }
            </div>
        </div>
    )
};

const UserInput = ({type,placeholder,value,setTo}) => {
    if(type === 'text'){
        return(
            <div className='New-User-Input-Text'>
                <input value={value} onChange={e => setTo(e.target.value)} className='New-User-Input' placeholder={placeholder}/>
            </div>
        )
    }
    else {
        return(
            <div className='New-User-Input-Select'>
                {   value &&
                    <ImCheckboxChecked className='New-User-Input-Selection' onClick={() => setTo(!value)}/>
                }
                {   !value &&
                    <ImCheckboxUnchecked className='New-User-Input-Selection' onClick={() => setTo(!value)}/>
                }
                <div className='New-User-Input-Title'>
                    {placeholder}
                </div>
            </div>
        )
    }

};

const Loader = () => {
    return (
      <RotatingLines
        strokeColor="rgb(6, 89, 79)"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    )
};

const NewUserDisplay = ({setAddingUser, submitNewUser, newUserId, errorMessage, setErrorMessage,setNewUserId}) => {
    const [ name, setName ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ vendorPriv, setVendorPriv ] = useState(false);
    const [ projectBookingPriv, setProjectBookingPriv ] = useState(false);
    const [ projectBookingReviewPriv, setProjectBookingReviewPriv ] = useState(false);
    const [ fixedAssetPriv, setFixedAssetPriv ] = useState(false);
    const [ fixedAssetReviewPriv, setFixedAssetReviewPriv ] = useState(false);
    const [ adminPriv, setAdminPriv ] = useState(false);

    const [ readyToSubmit, setReadyToSubmit ] = useState(false);

    const [ submitting, setSubmitting ] = useState(false);

    useEffect(() => {
        setErrorMessage('');
        setNewUserId(null);
    },[]);

    const checkInput = () => {
        let passed = true;

        if(name.length < 2) passed = false;
        if(email.length < 5) passed = false;

        return passed;
    };

    const checkPrivilege = () => {
        let passed = false;

        if(vendorPriv) passed = true;
        if(projectBookingPriv) passed = true;
        if(projectBookingReviewPriv) passed = true;
        if(fixedAssetPriv) passed = true;
        if(fixedAssetReviewPriv) passed = true;
        if(adminPriv) passed = true;

        return passed;
    };

    const addNewUser = () => {
        const userObject = {
            name: name,
            email: email,
            vendorPriv: vendorPriv,
            projectBookingPriv: projectBookingPriv,
            projectBookingReviewPriv: projectBookingReviewPriv,
            fixedAssetPriv: fixedAssetPriv,
            fixedAssetReviewPriv: fixedAssetReviewPriv,
            adminPriv: adminPriv,
        };

        setSubmitting(true);
        submitNewUser(userObject);
    };

    useEffect(() => {
        if(checkInput() & checkPrivilege()) setReadyToSubmit(true);
        else setReadyToSubmit(false);
    },[name, email, vendorPriv, projectBookingPriv,projectBookingReviewPriv,fixedAssetPriv,fixedAssetReviewPriv, adminPriv]);

    return(
        <div className='New-User-Display'>
            <div className='New-User-Window'>
                <div className='New-User-Header'>
                    <div className='New-User-Header-Title'>
                        Add User
                    </div>
                    <RxCross2 className='New-User-Header-Close' onClick={() => setAddingUser(false)}/>
                </div>

                {   !submitting &&
                <>
                    <div className='New-User-Instructions'>
                        Enter a name and email to add a new user.
                    </div>
                    <div className='New-User-Input-Window'>
                        <UserInput type={'text'} placeholder={'Full Name'} value={name} setTo={setName}/>
                        <UserInput type={'text'} placeholder={'Company Email'} value={email} setTo={setEmail}/>
                        <div className='New-User-Privilege-Title'>
                            User Privileges
                        </div>
                        <div className='New-User-Instructions'>
                            Select a privilege to add a new user.
                        </div>
                        <UserInput type={'select'} placeholder={'Vendor Requests'} value={vendorPriv} setTo={setVendorPriv}/>
                        <UserInput type={'select'} placeholder={'Project Booking'} value={projectBookingPriv} setTo={setProjectBookingPriv}/>
                        <UserInput type={'select'} placeholder={'Project Booking Review'} value={projectBookingReviewPriv} setTo={setProjectBookingReviewPriv}/>
                        <UserInput type={'select'} placeholder={'Fixed Assets'} value={fixedAssetPriv} setTo={setFixedAssetPriv}/>
                        <UserInput type={'select'} placeholder={'Fixed Assets Review'} value={fixedAssetReviewPriv} setTo={setFixedAssetReviewPriv}/>
                        <UserInput type={'select'} placeholder={'System Admin'} value={adminPriv} setTo={setAdminPriv}/>

                    </div>

                    {   readyToSubmit &&
                    <div className='New-User-Submit'>
                        <button className='New-User-Submit-Button' onClick={() => addNewUser()}>Invite User</button>
                    </div>
                    }
                </>
                }
                {   submitting &&
                    <div className='New-User-Submitting'>
                        {   !newUserId && !errorMessage &&
                        <>
                            <Loader />
                            <div className='New-User-Submitting-Text'>
                                Inviting new user.
                            </div>
                        </>
                        }
                        {   newUserId && !errorMessage &&
                        <>
                            <FaCheck className='New-User-Submitting-Symbol'/>
                            <div className='New-User-Submitting-Text'>
                                User has been invited.
                            </div>
                        </>
                        }
                        {   errorMessage &&
                        <>
                            <RxCross2 className='New-User-Failed-Symbol'/>
                            <div className='New-User-Submitting-Text'>
                                {errorMessage}
                            </div>
                        </>
                        }
                    </div>
                }
                
            </div>
        </div>
    )
};

const UsersWindow = () => {
    const contextObject = useContext(Context);

    const [ userList, setUserList ] = useState([]);
    const [ search, setSearch ] = useState('');
    const [ sortField, setSortField ] = useState('email');
    const [ direction, setDirection ] = useState('ASC');

    const [ addingUser, setAddingUser ] = useState(false);

    const [ newUserId, setNewUserId ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState('');

    useEffect(() => {
        getUserList({});
    },[search, sortField, direction]);

    const submitNewUser = async (userObject) => {
        userDataService.createUser(contextObject.apiToken,userObject)
        .then(response =>{
            console.log(response)
            setNewUserId(response.data);
        }).catch( e => {
            console.log(e.response)
            setErrorMessage(e.response.data);
        });
    };

    const resetUsersPassword = async (userObject) => {
        userDataService.resetPassword(contextObject.apiToken,userObject)
        .then(response =>{
          alert("Password reset has been sent to the user's email.");
        }).catch( e=> {
            alert(e.response.data);
        });
    };

    const updateExistingUser = async (userObject) => {
        userDataService.updateUser(contextObject.apiToken,userObject)
        .then(response =>{
            alert("User has been updated.");
        }).catch( e=> {
            alert(e.response.data);
        });
    };

    const createFilterObject = () => {
        const filterObject = {
            search:search,
            sortField:sortField,
            direction:direction,
        };
        return filterObject;
    };

    const getUserList = async () => {
        userDataService.getUserList(contextObject.apiToken,createFilterObject())
        .then(response =>{
            setUserList(response.data);
        }).catch( e=> {
            console.log(e.toString());
        });
    };

    return(
        <div className='Users-Window'>
            <UsersHeader setSearch={setSearch} search={search} userCount={userList.length} setAddingUser={setAddingUser}/>
            <UsersDisplay userList={userList} updateExistingUser={updateExistingUser} resetUsersPassword={resetUsersPassword} getUserList={getUserList}/>

            {   addingUser &&
                <NewUserDisplay setAddingUser={setAddingUser} submitNewUser={submitNewUser} newUserId={newUserId} errorMessage={errorMessage} setErrorMessage={setErrorMessage} setNewUserId={setNewUserId}/>
            }
        </div>
    )
};

export default UsersWindow;