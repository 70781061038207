import './LoadingSpinner.css';
import loadingSpinner from '../refs/loadingSpinner.svg';
import { useState } from 'react';

const LoadingSpinner = ({size}) => {
    const loadingSpinnerCss = {
        '--Spinner-Size': `${size}px`
    }
    return(
        <div className='Loading-Spinner' style={loadingSpinnerCss}>
            <img src={loadingSpinner} className='Loading-Spinner-Image'/>
        </div>
    )
};

export default LoadingSpinner;