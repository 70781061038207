import './Logout.css';
import { Context } from '../Context';
import { useContext } from 'react';


const Logout = () => {
    const contextObject = useContext(Context);


    return (
        <div className='Logout-Window'>
            <div className='Logout-Frame'>
                <div className='Logout-Title'>
                    Are you sure you want to log out?
                </div>
                <div className='Logout-Button-Group'>
                    <div className='Logout-Button-Confirm' onClick={() => contextObject.logoutUser()}>
                        Logout
                    </div>
                    <div className='Logout-Button-Cancel' onClick={() => contextObject.goHome()}>
                        Cancel
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Logout;