import { useEffect, useState } from 'react';
import './FixedAssets.css';


const FixedAssets = () => {
    const [testInput, setTestInput] = useState('');
    const [testInput1, setTestInput1] = useState('');

    useEffect(() => {
        console.log(testInput)
    },[testInput])

    return(
        <div className='Fixed-Assets'>

            <div className='Fixed-Assets-Temp'>
                New Fixed Assets Coming Soon 
            </div>

        </div>
    )
};

export default FixedAssets;