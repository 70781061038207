import './SetPassword.css';
import logo from '../refs/logo.jpg'
import loginText from '../refs/login.png'
import { useState,useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import userDataService from '../Services/UserService';

const Loader = () => {
    return (
      <RotatingLines
        strokeColor="rgb(6, 89, 79)"
        strokeWidth="5"
        animationDuration="0.75"
        width="96"
        visible={true}
      />
    )
};

const SetPassword = () => {
    const params = useSearchParams(); 
    const [ sessionId, setSessionId ] = useState('');
    const [ userId, setUserId ] = useState('');

    const [ password, setPassword ] = useState('');
    const [ confirmPassword, setConfirmPassword ] = useState('');
    const [ errorText, setErrorText ] = useState('Please enter a password with a length longer than 5 characters to continue');

    const [ ready, setReady ] = useState(false);
    const [ submitting, setSubmitting ] = useState(false);
    const [ completed, setCompleted ] = useState(false);



    useEffect(() => {
        if(password.length < 6) {
            setErrorText('Please enter a password with a length longer than 5 characters to continue');
            setReady(false);
        }
        else{
            if(password !== confirmPassword){
                setErrorText('The two passwords do not match. Please confirm both passwords are the same to continue.');
                setReady(false);
            }
            else{
                if(!sessionId){
                    setErrorText('Session Id has expired. Please contact a system administrator to resend link.');
                    setReady(false);
                }
                else{
                    setErrorText('');
                    setReady(true);
                };
            };
        };
    },[password,confirmPassword]);

    useEffect(() => { 
        const searchParams = params[0];
        if(searchParams.get("sessionId")) setSessionId(searchParams.get("sessionId"));
        if(searchParams.get("userId")) setUserId(searchParams.get("userId"));
    },[]);

    useEffect(() => {
        if(completed) setTimeout(() => {
            window.location.replace("https://cornerstoneinc.io");
        },1000);
    },[completed]);

    const sendPassword = async () => {
        setSubmitting(true);
        setErrorText('');

        const passwordObject = {
            sessionId: sessionId,
            userId: userId,
            password: password,
        };

        console.log(passwordObject)

        userDataService.setPassword(passwordObject)
        .then(response =>{
            setCompleted(true);
    
        }).catch( e=> {
            setErrorText(e.response.data);
        });
    };
    
    return(
        <div className='Set-Password-Window'>
            <div className='Set-Password-Box'>
                <img src={logo} className="Password-Image"/>
                <img src={loginText} className="Password-Image-Text"/>
                {   !submitting && !completed &&
                    <>
                    <input className='Password-Input' placeholder='Enter Password' type='password' onChange={e => setPassword(e.target.value)} value={password}/>
                    <input className='Password-Input' placeholder='Confirm Password' type='password' onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword}/>
                    {   errorText &&
                        <div className='Password-Error-Text'>
                            {errorText}
                        </div>
                    }
                    {   ready &&
                        <button className='Password-Button' onClick={() => sendPassword()}>Set Password</button>
                    }
                    </>
                }
                {   submitting && !completed &&
                    <div className='Password-Submitting'>
                            {   !errorText &&
                            <>
                                <Loader />
                                <div className='Password-Submitting-Text'>
                                    Setting Password
                                </div>
                            </>
                            }

                            { errorText &&
                            <>
                                <RxCross2 className='Password-Error-Symbol'/>
                                <div className='Password-Error-Text'>
                                    {errorText}
                                </div>
                            </>

                            }
                    </div>
                }
                {   completed &&
                    <div className='Password-Submitting'>
                        <FaCheck className='Password-Check'/>
                            <div className='Password-Submitting-Text'>
                                Password has been set.
                            </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default SetPassword;